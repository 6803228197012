<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="price_content" v-if="computedValue === `ko`">
			<div class="price_Header">
				<div class="Header_Name">모듈러스</div>
				<img :src="pricing_MLogo" />
				<div class="Header_Text">
					모듈러스 서비스는 월별 기간으로 비용을 지불하면 되어 사용중 추가 비용
					발생이나<br />
					종료 비용이 없으며, 구매 후 바로 사용이 가능합니다.
				</div>
			</div>

			<div class="price_Main">
				<div class="Intro_price">
					<div class="Intro_Name">요금안내</div>
					<div class="Intro_Version">
						<div class="Community_v">
							<div class="Com_Name">커뮤니티 버전</div>
							<div class="Com_Text">
								모듈러스의 모든 기능을 체험으로<br />
								무료로 사용할 수 있습니다.
							</div>
							<div class="Com_Price">사용료 무료</div>
							<a href="https://fcts-mod.com/" target="_blank">
								<button class="Com_use">서비스 체험 바로가기</button>
							</a>
						</div>
						<div class="Pro_V">
							<div class="Pro_NameBox">
								<div class="ProName">프로페셔널 버전</div>
								<div class="ProNew">New</div>
							</div>
							<div class="ProText">
								협업기능이 추가되어 실시간<br />
								협업사항과 업무를 공유할 수 있습니다.
							</div>
							<div class="Pro_price">월별 사용료 <span>78,000원</span></div>
							<router-link to="/paymentspopup">
								<button class="Pro_use">신청하기</button>
							</router-link>
						</div>
					</div>
				</div>

				<div class="M_price_Detail">
					<div class="Detail_Name">상세 기능 안내</div>
					<div class="Detail_Text">제공되는 서비스를 비교해 보세요!</div>
					<div class="IMGBtnTool">
						<img class="DetailIMG" :src="pricing_Modulus_Detail" />
						<router-link to="/servicesecurity">
							<button class="ServeBtn">
								서비스 바로가기
								<img :src="pricing_goToServe" />
							</button>
						</router-link>
					</div>
				</div>
			</div>
			<div id="pricing_content2">
				<div class="content2_Top">문의를 통해 빠른 안내를 받아보세요!</div>
				<div class="content2_Main">
					<div class="MainToTop">
						제품 이용에 궁금한 점이 있으시다면 문의사항을 남겨주세요<br />
						빠른 시일내 연락을 드리겠습니다.
					</div>

					<div class="content2_BtnBox">
						<router-link to="/inquiry">
							<button class="askBox">1:1 문의하기</button>
						</router-link>
						<a href="https://fcts-mod.com/how_to_use">
							<button class="asksBox">제품 사용 방법</button>
						</a>
					</div>
					<div class="content2_ServiceDay">
						상담시간 : 평일 10:00 ~ 17:00 (주말/공휴일 휴무)<br />
						이메일 상담 : 24시간 가능<br />
						<span>대표 번호 : 010-3901-4816</span>
					</div>
				</div>
			</div>
		</div>
		<!-- mobile -->
		<div class="mobile_MPrice" v-if="computedValue === `ko`">
			<div class="Mobile_Header">
				<div class="MainHeader">모듈러스</div>
				<img :src="pricing_MLogo" />
				<div class="SubText">
					모듈러스 서비스는 월별 기간으로 비용을 지불하면 되어<br />
					사용중 추가 비용 발생이나 종료 비용이 없으며,<br />
					구매 후 바로 사용이 가능합니다.
				</div>
			</div>

			<div class="Mmb_cont1">
				<div class="Mmb_Cont1Head">요금안내</div>
				<div class="SubHead">※요금결제 및 무료체험은 PC에서만 가능합니다.</div>
				<div class="Boxss">
					<div class="head">커뮤니티 버전</div>
					<div class="text">
						모듈러스의 모든 기능을 체험으로<br />
						무료로 사용할 수 있습니다.
					</div>
					<button class="Btn">체험판 무료</button>
				</div>
				<div class="Boxss">
					<div class="SubHeadBox">
						<div class="LeftHead">프로페셔널 버전</div>
						<div class="NewHead">New</div>
					</div>
					<div class="text">
						협업기능이 추가되어 실시간<br />
						협업사항과 업무를<br />
						공유할수 있습니다.
					</div>
					<button class="Btn">요금 확인</button>
				</div>
			</div>

			<div class="Mmb_cont2">
				<div class="C2-image"><img :src="Pc__Modulus" /></div>
				<div class="C2-text">
					<div class="Head">프로페셔널 버전</div>
					<div class="Conts">Premium Version</div>
				</div>
			</div>
			<div class="Mmb_cont2_1">
				<div class="LikeMod">
					제공되는 서비스를 확인해 보세요!<br />
					모듈러스만의 특장점입니다.
				</div>
				<div class="ProBoxxs">
					<div class="box">파이썬 코드 편집 및 실행</div>
					<div class="box">데이터 프레임 자동 가시화</div>
					<div class="box">실시간 코드 분석 및 데이터 표현</div>
					<div class="box">가시화 데이터 컨트롤 창 제공</div>
					<div class="box">로컬 파일 호출 및 저장</div>
					<div class="box">
						실시간 협업 기능<br />
						※최대 10개프로젝트, 최대 20명
					</div>
					<div class="box">소스코드 저장 및 공유</div>
					<div class="box">팀원 간 파일 공유</div>
					<div class="box">ECHC를 적용하여 안전한 데이터 전송</div>
				</div>
			</div>

			<div class="Mmb_cont4">
				<div class="Cont4_Ask">문의를 통해 빠른 안내를 받아보세요!</div>
				<div class="Cont4_Fl">
					제품 이용에 궁금한 점이 있으시다면<br />
					문의 사항을 남겨 주세요.<br />
					빠른 시일 내 연락을 드리겠습니다.
				</div>
				<div class="Cont4_Time">
					상담시간 : 평일 10:00 ~ 17:00 (주말/공휴일 휴무) <br />이메일 상담 :
					24시간 가능
				</div>

				<button class="Cont4_btn">010-3901-4816</button>
				<router-link to="/inquiry">
					<button class="Cont4_btn">1:1 문의하기</button>
				</router-link>
			</div>
		</div>

		<div class="price_content" v-if="computedValue === `en`">
			<div class="price_Header">
				<div class="Header_Name">Modulus</div>
				<img :src="pricing_MLogo" />
				<div class="Header_Text">
					The Modulus service requires payment on a monthly basis,<br />
					with no additional costs incurred during usage or at the end of the
					period.<br />It can be used immediately after purchase.
				</div>
			</div>

			<div class="price_Main">
				<div class="Intro_price">
					<div class="Intro_Name">Pricing Guide</div>
					<div class="Intro_Version">
						<div class="Community_v">
							<div class="Com_Name">Community Ver.</div>
							<div class="Com_Text">
								Experience all the features of<br />
								Modulus for free.
							</div>
							<div class="Com_Price">No usage fee</div>
							<a href="https://fcts-mod.com/" target="_blank">
								<button class="Com_use">Go to service experience</button>
							</a>
						</div>
						<div class="Pro_V">
							<div class="Pro_NameBox">
								<div class="ProName">Professional Ver.</div>
								<div class="ProNew">New</div>
							</div>
							<div class="ProText">
								With added collaboration features,<br />
								you can share real-time updates and tasks.
							</div>
							<div class="Pro_price">
								Monthly usage fee: <span>78,000 KRW</span>
							</div>
							<button class="Pro_use" @click="openPaymentsPopup()">
								Apply Now
							</button>
						</div>
					</div>
				</div>

				<div class="M_price_Detail">
					<div class="Detail_Name">Detailed Feature Guide</div>
					<div class="Detail_Text">Compare the services offered!</div>
					<div class="IMGBtnTool">
						<img class="DetailIMG" :src="pricing_Modulus_Detailen" />
						<router-link to="/servicesecurity">
							<button class="ServeBtn">
								Go to service
								<img :src="pricing_goToServe" />
							</button>
						</router-link>
					</div>
				</div>
			</div>
			<div id="pricing_content2">
				<div class="content2_Top">
					Please get quick guidance through inquiries!
				</div>
				<div class="content2_Main">
					<div class="MainToTop">
						If you have any questions about using the product,<br />
						please leave your inquiries. We will get back<br />to you as soon as
						possible.
					</div>

					<div class="content2_BtnBox">
						<router-link to="/inquiry">
							<button class="askBox">1:1 Contact</button>
						</router-link>
						<a href="https://fcts-mod.com/how_to_use">
							<button class="asksBox">How to use the product</button>
						</a>
					</div>
					<div class="content2_ServiceDay">
						Consultation Hours: Weekdays 10:00 AM - 5:00 PM<br />(Closed on
						weekends and holidays)<br />
						Email Consultation: Available 24 hours<br />
						<span>Phone Number: 010-3901-4816</span>
					</div>
				</div>
			</div>
		</div>
		<!-- mobile -->
		<div class="mobile_MPrice" v-if="computedValue === `en`">
			<div class="Mobile_Header">
				<div class="MainHeader">Modulus</div>
				<img :src="pricing_MLogo" />
				<div class="SubText">
					The Modulus service requires payment on a monthly basis,<br />
					with no additional costs incurred during usage or at the end of the
					period.<br />
					It can be used immediately after purchase.
				</div>
			</div>

			<div class="Mmb_cont1">
				<div class="Mmb_Cont1Head">Pricing Guide</div>
				<div class="SubHead">
					※Payment and free trial are available only on PC.
				</div>
				<div class="Boxss">
					<div class="head">Community Ver.</div>
					<div class="text">
						Experience all the features of<br />
						Modulus for free.
					</div>
					<button class="Btn">Free Trial</button>
				</div>
				<div class="Boxss">
					<div class="SubHeadBox">
						<div class="LeftHead">Professional Ver.</div>
						<div class="NewHead">New</div>
					</div>
					<div class="text">
						With added collaboration features,<br />
						you can share real-time updates<br />
						and tasks.
					</div>
					<button class="Btn">Check Pricing</button>
				</div>
			</div>

			<div class="Mmb_cont2">
				<div class="C2-image"><img :src="Pc__Modulus" /></div>
				<div class="C2-text">
					<div class="Head">Professional Ver.</div>
				</div>
			</div>
			<div class="Mmb_cont2_1">
				<div class="LikeMod">
					Compare the services offered!<br />
					This is a unique feature of Modulus
				</div>
				<div class="ProBoxxs">
					<div class="box">Python code editing and execution</div>
					<div class="box">Automatic visualization of data frames</div>
					<div class="box">Real-time code analysis and data representation</div>
					<div class="box">Visualization data control panel provided</div>
					<div class="box">Local file calling and saving</div>
					<div class="box">
						Real-time collaboration feature<br />
						※Up to 10 projects, up to 20 people
					</div>
					<div class="box">Source code storage and sharing</div>
					<div class="box">File sharing among team members</div>
					<div class="box">Secure data transmission using ECHC</div>
				</div>
			</div>

			<div class="Mmb_cont4">
				<div class="Cont4_Ask">
					Please get quick guidance through inquiries!
				</div>
				<div class="Cont4_Fl">
					If you have any questions about using the product,<br />
					please leave your inquiries. We will get back<br />
					to you as soon as possible.
				</div>
				<div class="Cont4_Time">
					Consultation Hours: Weekdays 10:00 AM - 5:00 PM<br />
					(Closed on weekends and holidays) <br />Email Consultation: Available
					24 hours
				</div>

				<button class="Cont4_btn">010-3901-4816</button>
				<router-link to="/inquiry">
					<button class="Cont4_btn">1:1 Contact</button>
				</router-link>
			</div>
		</div>
		<!-- <div class="pricing_container">
			<div class="pricing_title">
				<h1>보안 플랫폼 요금 계산기</h1>
				<p>
					원하는 플랫폼을 선택하고 요금 계산기로 대략의 요금을 측정해 보세요<br /><br />
					<span>※월 사용량을 기준으로 결제 됩니다※</span>
				</p>
			</div>
			pricing_title end
			<div id="pricing_form">
				<div class="form_pricing_group">
					<label>PKI 알고리즘</label>
					<input
						type="text"
						v-model="option1"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
				<div class="form_pricing_group">
					<label>키 사이즈</label>
					<input
						type="text"
						v-model="option2"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
				<div class="form_pricing_group">
					<label>키 개수</label>
					<input
						type="text"
						v-model="option3"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>
			</div>
			pricing_form
			<div id="pricing_form2">
				<div class="form_pricing_group">
					<label>DB 연동 유무</label>
					<select v-model="selected">
						<option value="예">예</option>
						<option value="아니오">아니오</option>
					</select>
				</div>

				<div class="form_pricing_group">
					<label>로그 테이블 개수</label>
					<input
						type="text"
						v-model="option5"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
				</div>

				<div class="form_pricing_group">
					<label>로그 데이터 양</label>
					<input
						type="text"
						v-model="option6"
						maxlength="10"
						oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
					/>
					<input
						type="checkbox"
						class="checkbox"
						v-model="checkedBox"
						true-value="yes"
						false-value="no"
					/>
					<p>이상분석</p>
				</div>
			</div>
			pricing_form2
			<div class="form_pricing_group_button">
				<button @click="showsecurityResult">요금 결과</button>
			</div>
			form__group_button

			<div id="pricing_result_container">
				<div class="title_content">
					<div class="form_result_title">
						<p>요금표시 <i class="fas fa-caret-down"></i></p>
					</div>
					form_result_title
					<div class="form_result_content">
						<div class="form_result_box form_security_result_box">
							<label>PKI 알고리즘 : </label>
							<span>{{ this.result1 }}</span>
						</div>
						<div class="form_result_box form_security_result_box">
							<label>키 사이즈 : </label>
							<span>{{ this.result2 }}</span>
						</div>
						<div class="form_result_box form_security_result_box">
							<label>키 개수 : </label>
							<span>{{ this.result3 }}</span>
						</div>
						<div class="form_result_box form_security_result_box">
							<label>DB 연동 유무 : </label>
							<span>{{ this.selected_result }}</span>
						</div>
						<div class="form_result_box form_security_result_box">
							<label>로그 테이블 개수 : </label>
							<span>{{ this.result5 }}</span>
						</div>
						<div class="form_result_box form_security_result_box">
							<label>로그 데이터 양 : </label>
							<span>{{ this.result6 }}</span>
						</div>
					</div>
					form_result_content end
				</div>
				title_content end
				<div class="form_result_pricing">
					<div class="form_result_box">
						<label>총 요금 예상 비용</label>
						<span>{{ this.pricing }}</span>
					</div>
					<div class="pricing_button_box">
						<button class="file">파일 다운로드</button>
						<button class="pay">결제하기</button>
					</div>
				</div>
				form_result_pricing end
			</div>
			pricing_result_container end
		</div>
		pricing_container -->
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
import pricing_MLogo from '@/assets/images/pricing/pricing_MLogo.svg';
import pricing_Modulus_Detail from '@/assets/images/pricing/pricing_Modulus_Detail.svg';
import pricing_Modulus_Detailen from '@/assets/images/pricing/pricing_Modulus_Detailen.svg';
import pricing_SaleEvent from '@/assets/images/pricing/pricing_SaleEvent.svg';
import pricing_goToServe from '@/assets/images/pricing/pricing_goToServe.svg';
import Pc_pricingSale from '@/assets/images/pricing/Pc_pricingSale.svg';
import Pc__Modulus from '@/assets/images/service/Pc__Modulus.svg';

export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			pricing_MLogo,
			pricing_Modulus_Detail,
			pricing_Modulus_Detailen,
			pricing_SaleEvent,
			Pc__Modulus,
			Pc_pricingSale,
			pricing_goToServe,
			// menuKr: '모듈러스 요금안내',
			menuEn: 'Modulus Price',
			option1: '',
			result1: '',
			option2: '',
			result2: '',
			option3: '',
			result3: '',
			option5: '',
			result5: '',
			option6: '',
			result6: '',
			selected: '예',
			checkedBox: '',
			selected_result: '',
			pricing: '',
			popupView: false,
		};
	},
	computed: {
		isUserlogin() {
			return this.$store.getters.isLogin;
		},
		computedValue() {
			return this.$store.state.language;
		},
		menuKr() {
			return this.computedValue === 'ko' ? '모듈러스 요금안내' : '';
		},
	},
	methods: {
		showsecurityResult() {
			{
				this.result1 = this.option1;
				this.result2 = this.option2;
				this.result3 = this.option3;
				this.result5 = this.option5;
				this.result6 = this.option6;
				this.selected_result = this.selected;
				if (this.checkedBox == 'yes') {
					this.pricing =
						(this.option1 +
							this.option2 +
							this.option3 +
							this.option5 +
							this.option6 * 2) *
						300 *
						30;
					console.log('이상분석일 경우', this.pricing);
				} else {
					this.pricing =
						(this.option1 +
							this.option2 +
							this.option3 +
							this.option5 +
							this.option6) *
						300 *
						30;
					console.log('이상분석 아닐 경우', this.pricing);
				}
			}
		},
		openPaymentsPopup() {
			const bodyWidth = window.screen.width;
			const bodyHeight = window.screen.height;
			const popupWidth = 1058;
			const popupHeight = 999;

			window.open(
				'/paymentspopup',
				'_blank',
				`width=${popupWidth}, height=${popupHeight}, top=${
					bodyHeight / 2 - popupHeight / 2
				}, left=${bodyWidth / 2 - popupWidth / 2}`,
			);
		},
	},
};
</script>

<style></style>
